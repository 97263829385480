import axios from "../../utils/axios";

export const getBlog = async (postID) => {
    const response = await axios.get(`/blogs/${postID}`);
    return response.data;
};

export const updateLikes = async (postID, likes) => {
    const response = await axios.patch(`/blogs/${postID}`, { likes });
    return response.data;
};

export const saveBlog = async (postID, isSaved) => {
    const response = await axios.patch(`/blogs/${postID}`, { isSaved });
    return response.data;
};
