const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    sortValue: "",
    filterValue: false,
};

const sortFilterSlice = createSlice({
    name: "sortFilter",
    initialState,
    reducers: {
        sortSelected: (state, action) => {
            state.sortValue = action.payload;
        },
        filterSelected: (state, action) => {
            state.filterValue = action.payload;
        },
    },
});

export default sortFilterSlice.reducer;
export const { sortSelected, filterSelected } = sortFilterSlice.actions;