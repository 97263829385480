import axios from "../../utils/axios";

export const getBlogs = async (sortValue, filterValue) => {
    let queryString = "";

    if (sortValue !== "") queryString += `_sort=${sortValue}&_order=desc`;
    if (filterValue) queryString += `&isSaved=${filterValue}`;

    const response = await axios.get(`/blogs?${queryString}`);
    return response.data;
};