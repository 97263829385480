import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getBlog, updateLikes, saveBlog } from "./blogAPI";

const initialState = {
    blog: {},
    isLoading: false,
    isError: false,
    errorMessage: ""
};

// async thunk
export const fetchBlog = createAsyncThunk(
    'blog/fetchBlog',
    async (postID) => {
        const response = await getBlog(postID);
        return response;
    }
);

export const updateBlogLikes = createAsyncThunk(
    'blog/updateBlogLikes',
    async (payload) => {
        const response = await updateLikes(payload.postID, payload.likes);
        return response;
    }
);


export const updateBlogSave = createAsyncThunk(
    'blog/updateBlogSave',
    async (payload) => {
        const response = await saveBlog(payload.postID, payload.isSaved);
        return response;
    }
);

const blogSlice = createSlice({
    name: 'blog',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchBlog.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = "";
            })
            .addCase(fetchBlog.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.errorMessage = "";
                state.blog = action.payload;
            })
            .addCase(fetchBlog.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.blog = {};
                state.errorMessage = action.error?.message;
            })
            .addCase(updateBlogLikes.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.errorMessage = "";
                state.blog = action.payload;
            })
            .addCase(updateBlogLikes.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.blog = {};
                state.errorMessage = action.error?.message;
            })
            .addCase(updateBlogSave.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.errorMessage = "";
                state.blog = action.payload;
            })
            .addCase(updateBlogSave.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.blog = {};
                state.errorMessage = action.error?.message;
            });
    }
});

export default blogSlice.reducer;