import React from "react";

import Sidebar from "../components/Sidebar";
import PostGrid from "../components/PostGrid";

const Home = () => {
  return (
    <section className="wrapper">
      <Sidebar />
      <PostGrid />
    </section>
  );
};

export default Home;
