import React from "react";
import { Link } from "react-router-dom";

import Tags from "./Tags";

const RelatedPostItem = ({ blog = {} }) => {
  const { id, title, image, tags, createdAt } = blog;
  return (
    <div className="card">
      <Link to={`/post/${id}`}>
        <img src={image} className="card-image" alt={title} />
      </Link>
      <div className="p-4">
        <Link
          to={`/post/${id}`}
          className="text-lg post-title lws-RelatedPostTitle"
        >
          {title}
        </Link>
        <Tags tags={tags} />
        <p>{createdAt}</p>
      </div>
    </div>
  );
};

export default RelatedPostItem;
