import React from "react";

const Tags = ({ tags = [] }) => {
  return (
    <div className="lws-tags">
      {tags.map((tag) => (
        <span key={tag}>#{tag}</span>
      ))}
    </div>
  );
};

export default Tags;
