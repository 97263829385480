/**
 * @description - convert array of string to string
 * @param {Array} arr - ["javascript", "react"]
 * @returns {String} tagStr - "?tags_like=javascript&tags_like=react"
 */
export const formatArrToStr = (arr) => {
    let tagStr = "";

    arr.forEach((tag, index) => {
        if (index === 0) tagStr += `?tags_like=${tag}`;
        else tagStr += `&tags_like=${tag}`;
    });
    return tagStr;
};


/**
 * @description - convert string to boolean
 * @param {String} str true or false
 * @returns {Boolean} true or false
 */
export const parseBool = (str) => {
    if (str === "true") return true;

    return false;
};