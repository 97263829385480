import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { MoonLoader } from "react-spinners";

import {
  fetchBlog,
  updateBlogLikes,
  updateBlogSave,
} from "../features/blog/blogSlice";
import Tags from "../components/Tags";
import RelatedPostsGrid from "../components/RelatedPostsGrid";

const Post = () => {
  const dispatch = useDispatch();
  const { postID } = useParams();

  const { blog, isLoading, isError, errorMessage } = useSelector(
    (state) => state.blog
  );
  const { title, image, description, tags, likes, isSaved } = blog;

  const likeClickHandler = () => {
    dispatch(updateBlogLikes({ postID, likes: likes + 1 }));
  };

  const saveClickHandler = () => {
    dispatch(updateBlogSave({ postID, isSaved: !isSaved }));
  };

  useEffect(() => {
    dispatch(fetchBlog(postID));
  }, [dispatch, postID]);

  return (
    <div>
      <div className="container mt-8">
        <Link
          to="/"
          className="inline-block text-gray-600 home-btn"
          id="lws-goHome"
        >
          <i className="mr-2 fa-solid fa-house"></i>Go Home
        </Link>
      </div>
      <section className="post-page-container">
        {isLoading && <MoonLoader />}
        {isError ? (
          <strong style={{ color: "red" }}>{errorMessage}</strong>
        ) : (
          <>
            <main className="post">
              <img
                src={image}
                alt={title}
                className="w-full rounded-md"
                id="lws-megaThumb"
              />
              <div>
                <h1 className="mt-6 text-2xl post-title" id="lws-singleTitle">
                  {title}
                </h1>
                <Tags tags={tags} />
                <div className="btn-group">
                  <button
                    className="like-btn"
                    id="lws-singleLinks"
                    onClick={likeClickHandler}
                  >
                    <i className="fa-regular fa-thumbs-up"></i> {likes}
                  </button>

                  <button
                    class={`${isSaved && "active"} save-btn`}
                    id="lws-singleSavedBtn"
                    onClick={saveClickHandler}
                  >
                    <i className="fa-regular fa-bookmark"></i>
                    {isSaved ? " Saved" : " Save"}
                  </button>
                </div>
                <div className="mt-6">
                  <p>{description}</p>
                </div>
              </div>
            </main>

            <RelatedPostsGrid />
          </>
        )}
      </section>
    </div>
  );
};

export default Post;
