import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MoonLoader } from "react-spinners";

import PostItem from "./PostItem";
import { fetchBlogs } from "../features/blogs/blogsSlice";

const PostGrid = () => {
  const { blogs, isLoading, isError, errorMessage } = useSelector(
    (state) => state.blogs
  );
  const { sortValue, filterValue } = useSelector((state) => state.sortFilter);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBlogs({ sortValue, filterValue }));
  }, [dispatch, filterValue, sortValue]);

  return (
    <main className="post-container" id="lws-postContainer">
      {isLoading && <MoonLoader />}
      {isError ? (
        <strong style={{ color: "red" }}>{errorMessage}</strong>
      ) : (
        blogs.map((blog) => <PostItem key={blog.id} post={blog} />)
      )}
    </main>
  );
};

export default PostGrid;
