import React from "react";
import { useDispatch } from "react-redux";

import {
  sortSelected,
  filterSelected,
} from "../features/sortFilter/sortFilterSlice";
import { parseBool } from "../utils/utils";

const Sidebar = () => {
  const dispatch = useDispatch();

  const handleSort = (e) => {
    dispatch(sortSelected(e.target.value));
  };

  const handleFilter = (e) => {
    dispatch(filterSelected(parseBool(e.target.value)));
  };

  return (
    <aside>
      <div className="sidebar-items">
        <div className="sidebar-content">
          <h4>Sort</h4>
          <select
            name="sort"
            id="lws-sort"
            className="w-full max-w-[150px] border-2 rounded-md text-gray-500"
            onChange={handleSort}
          >
            <option value="">Default</option>
            <option value="createdAt">Newest</option>
            <option value="likes">Most Liked</option>
          </select>
        </div>
        <div className="sidebar-content">
          <h4>Filter</h4>
          <div className="radio-group" onChange={handleFilter}>
            <div>
              <input
                type="radio"
                name="filter"
                id="lws-all"
                defaultChecked={true}
                value={false}
                className="radio"
              />
              <label htmlFor="lws-all">All</label>
            </div>
            <div>
              <input
                type="radio"
                name="filter"
                id="lws-saved"
                className="radio"
                value={true}
              />
              <label htmlFor="lws-saved">Saved</label>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
