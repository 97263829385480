import React from "react";
import { useNavigate } from "react-router-dom";

const Error404 = () => {
  const navigate = useNavigate();
  return (
    <div>
      <h3>This is a 404 page!</h3>
      <button onClick={() => navigate("/")}>Go to Home</button>
    </div>
  );
};

export default Error404;
