import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getBlogs } from "./blogsAPI";

const initialState = {
    blogs: [],
    isLoading: false,
    isError: false,
    errorMessage: ""
};

// async thunk
export const fetchBlogs = createAsyncThunk(
    'blogs/fetchBlogs',
    async ({ sortValue, filterValue }) => {
        const response = await getBlogs(sortValue, filterValue);
        return response;
    }
);

const blogsSlice = createSlice({
    name: 'blogs',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchBlogs.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = "";
            })
            .addCase(fetchBlogs.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.errorMessage = "";
                state.blogs = action.payload;
            })
            .addCase(fetchBlogs.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.blogs = [];
                state.errorMessage = action.error?.message;
            });
    }
});

export default blogsSlice.reducer;