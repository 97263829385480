import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getRelatedBlogs } from "./relatedBlogsAPI";

const initialState = {
    relatedBlogs: [],
    isLoading: false,
    isError: false,
    errorMessage: ""
};

// async thunk
export const fetchRelatedBlogs = createAsyncThunk(
    'relatedBlogs/fetchRelatedBlogs',
    async ({ tags, id }) => {
        const response = await getRelatedBlogs(tags, id);
        return response;
    }
);

const relatedBlogsSlice = createSlice({
    name: 'relatedBlogs',
    initialState,
    extraReducers: (builder) => {
        builder
            // .addCase(fetchRelatedBlogs.pending, (state) => {
            //     state.isLoading = true;
            //     state.isError = false;
            //     state.errorMessage = "";
            // })
            .addCase(fetchRelatedBlogs.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.errorMessage = "";
                state.relatedBlogs = action.payload;
            })
            .addCase(fetchRelatedBlogs.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.relatedBlogs = [];
                state.errorMessage = action.error?.message;
            });
    }
});

export default relatedBlogsSlice.reducer;