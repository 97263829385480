import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MoonLoader } from "react-spinners";

import RelatedPostItem from "./RelatedPostItem";
import { fetchRelatedBlogs } from "../features/relatedBlogs/relatedBlogsSlice";

const RelatedPostsGrid = () => {
  const dispatch = useDispatch();

  const { tags, id } = useSelector((state) => state.blog.blog);

  const { relatedBlogs, isLoading, isError, errorMessage } = useSelector(
    (state) => state.relatedBlogs
  );

  useEffect(() => {
    dispatch(fetchRelatedBlogs({ tags, id }));
  }, [dispatch, id, tags]);

  return (
    <aside>
      <h4 className="mb-4 text-xl font-medium" id="lws-relatedPosts">
        Related Posts
      </h4>
      {isLoading && <MoonLoader />}
      {isError ? (
        <strong style={{ color: "red" }}>{errorMessage}</strong>
      ) : relatedBlogs.length > 0 ? (
        relatedBlogs.map((blog) => (
          <RelatedPostItem key={blog.id} blog={blog} />
        ))
      ) : (
        <p>No related posts found</p>
      )}
    </aside>
  );
};

export default RelatedPostsGrid;
